<template>
  <div>
    <el-row class="bgW">
      <el-col :span="18">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          
           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          
        </el-form>
      </el-col>
      <el-col :span="6" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary" class="mb10" size="mini" @click="taskFrame = true">添加任务</el-button>
      <el-button type="primary" class="mb10" size="mini" @click="exportExcel" :loading="exportS">导出数据</el-button>
      </el-col>
      </el-row>
    <div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="tableData"
            size="small"
			height="2500"
            border
            v-loading="loading"
            :span-method="objectSpanMethod"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="客服" prop="realname" />
            <ElTableColumn label="客服目标" prop="moneth_task" />
            <ElTableColumn label="来源" prop="source_name" />
            <ElTableColumn label="录入总数" prop="luruzongshu" />
            <ElTableColumn label="转入直营" prop="zhuanruzhiying" />
            <ElTableColumn label="转入加盟" prop="zhuanrujiameng" />
            <ElTableColumn label="到店总数" prop="daodianzongshu" />
            <ElTableColumn label="成交数" prop="chengjiaoshu" />
            <ElTableColumn label="成交额" prop="chengjiaoe" />
            <ElTableColumn label="直营转出率" prop="zhiyingzhuanchulv" />
            <ElTableColumn label="直营进店率" prop="zhiyingjindianlv" />
            <ElTableColumn label="加盟进店率" prop="jiamengjindianlv" />
            <ElTableColumn label="成交率" prop="chengjialv" />

          </el-table>
          <!-- <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          /> -->
        </el-col>
      </el-row>
    </div>
    <!-- 新增弹框 -->
    <el-dialog title="新增客服月度任务" :visible.sync="taskFrame" 
    :close-on-click-modal="false" width="30%" @close="closeTask">
      <el-form
        ref="taskDetail"
        label-position="right"
        label-width="120px"
        :rules="rules"
        :model="taskDetail"
        class="frame"
        size="mini"
      >
        <el-form-item label="客服名称:" prop="kf_user_id">
          <el-select
              v-model="taskDetail.kf_user_id"
              clearable
              remote
              filterable
              v-loading="loading"
              class="w300"
              :remote-method="remoteShop"
              placeholder="请输入客服名称"
            >
              <el-option
                v-for="item in options.kf_user_id"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              />
            </el-select>
        </el-form-item>
        <el-form-item label="任务数量" prop="task_num">
          <el-input-number class="w300" v-model="taskDetail.task_num" :min="1" :precision="0" ></el-input-number>
        </el-form-item>
        <el-form-item label="年/月" prop="moneth">
          <el-date-picker
            v-model="time"
            type="month"
            class="w300"
            value-format="yyyy-MM"
            @change="changeTime"
            placeholder="选择月份">
          </el-date-picker>
        </el-form-item>
        
        <div class="footerBtn mt50">
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('taskDetail')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import { skTask,clientServe,monethCustomer } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class TaskDetail {
  kf_user_id = ""; // 店铺id
  task_num = 1; // 任务数量正整数
  year = ''; // 年份
  moneth = ''; // 月份
}
class Rules {
 kf_user_id = [
    { required: true, message: "请选择客服", trigger: "change" }
  ];
 task_num = [
    { required: true, message: "请输入任务数量", trigger: "change" }
  ];
 year = [
    { required: true, message: "请输选择年份", trigger: "change" }
  ];
 moneth = [
    { required: true, message: "请输选择月份", trigger: "change" }
  ];
}
class Options {
  kf_user_id = [];
}
class Search {
  start_time = "";
  end_time = "";
}
export default {
  name: 'OrderList',
  components: {
    // pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      taskDetail: new TaskDetail(), 
      rules: new Rules(), // 校验
      options: new Options(), //选项
      page: new Page(), // 分页
      list: [], // 数据展示
      taskFrame: false,
      loading: false,
      exportS: false,
      time: '',
    }
  },
computed: {
    tableData() {
      const res = this.list.reduce((res, item) => {
        item.maxnum = 0;
        if (item.list && item.list.length > 0) {
          const goods = item.list.map(goods => Object.assign({}, item, goods));
          goods[0].maxnum = item.list.length;
          res = res.concat(goods);
        } else {
          res.push(item);
        }
        return res;
      }, []);
      return res;
    }
  },
  mounted () {
    this.remoteShop()
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01' 
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
  },
  methods: {
    getList(option) {
      this.loading = true
      if (option === "search") this.page = new Page();
      monethCustomer({  ...this.search,export:0 }).then(res => {
        this.list = res.data.list;
        this.loading = false
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        return {
          rowspan: row.maxnum,
          colspan: 1
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    // 查找
   remoteShop(e) {
      clientServe({ page: 1, limit: 100000, shopName: e?e:'' }).then(res => {
        this.options.kf_user_id = res.list;
      });
    },
     // 选择时间
    changeTime(e){
      var index = e.lastIndexOf("-");
      this.taskDetail.year = e.substring(0, index);
      this.taskDetail.moneth = e.substring(index+1,e.length);
    },
    // 关闭弹窗
    closeTask(){
      this.taskDetail = new TaskDetail()
      this.time = ''
    },
    submitForm(formName) {
      // this.checking();
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("添加此月度任务");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    confirm(){
      skTask({ ...this.taskDetail }).then(res => {
         this.$message({
                type: "success",
                message: res.msg
                });
                this.taskFrame = false;
                this.getList();
      });
    },
// 导出
    exportExcel() {
      this.exportS = true
      monethCustomer({...this.search,export:1}).then(res => {
        // console.log(res)
        window.open(res.data.url);
        this.exportS = false
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.footerBtn,.btnBox {
  text-align: right;
}
</style>
